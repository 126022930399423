import {
  ActiveElement,
  Chart as ChartJS, ChartConfiguration, ChartData, ChartEvent, ChartOptions,
} from "chart.js"
import { DeltaStats, TrendsApi } from "../services/TrendsApi"
import { FormatTrafficRate } from "./TrendsTable"
import { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import { Dropdown } from "./Dropdown"
import { RegionSelector } from "./RegionSelector"
import {
  TreemapController,
  TreemapControllerDatasetOptions,
  TreemapDataPoint,
  TreemapElement,
  TreemapScriptableContext,
} from "chartjs-chart-treemap"
import { ALL, useParamsContext } from "../context/ParamsContext"
import { useNavigate } from "react-router"
import { Chart } from "react-chartjs-2"
import { color } from "chart.js/helpers"
import { TimeInterval } from "./SelectTime"
import zoomPlugin from "chartjs-plugin-zoom"

ChartJS.register(
  TreemapElement,
  TreemapController,
  zoomPlugin,
)

export type Scope = "weekly" | "monthly" | "weekly_peak" | "monthly_peak"

const scopeOptions: {value: Scope, label: string}[] = [
  { value: "weekly", label: "Last week average" },
  { value: "monthly", label: "Last month average" },
  { value: "weekly_peak", label: "Last week peak" },
  { value: "monthly_peak", label: "Last month peak" },
]

type BandwidthData = {
  name: string
  organization_id: string
  bandwidth: number
  gradient?: number
  base_color: string
};

function parseScopeData(scopeData: DeltaStats[]) {
  const bandwidths: BandwidthData[] = []
  for (const org of scopeData) {
    const item: BandwidthData = {
      name: org.organisation.name,
      organization_id: org.organisation.id,
      bandwidth: org.current_value,
      base_color: "blue",
    }
    bandwidths.push(item)
  }
  const sortedBandwidths: BandwidthData[] = bandwidths.sort((n1,n2) => {
    if (n1.bandwidth > n2.bandwidth) {
      return -1
    }
    if (n1.bandwidth < n2.bandwidth) {
      return 1
    }
    return 0
  })
  let currentGradient = 0.6
  const gradientDelta = 0.007
  const minGradient = 0.1
  for (const item of sortedBandwidths) {
    item.gradient = Math.max(currentGradient, minGradient)

    if (/JSC/.test(item.name)) { // Russian customer
      item.base_color = "red"
    }
    currentGradient -= gradientDelta
  }
  return sortedBandwidths
}

export const BandwidthTreemap = () => {
  const [config, setConfig] = useState<ChartConfiguration>({ type: "treemap", data: { datasets: [] } })
  const { setContextOrganisationId } = useParamsContext()
  const navigate = useNavigate()
  const [selectedScope, setSelectedScope] = useState<Scope>("weekly")
  const [selectedRegion, setSelectedRegion] = useState<string>()

  const handleClick = (_: ChartEvent, arr: ActiveElement[]) => {
    if (arr.length > 0) {
      const dataset = config.data.datasets[0] as TreemapControllerDatasetOptions<BandwidthData>
      const tree = dataset.tree as {[index: number]: BandwidthData}
      navigateToChart(tree[arr[0].index].organization_id)
    }
  }

  const navigateToChart = (organisationId: string) => {
    const hashParams = new URLSearchParams()
    const timeRange = selectedScope.split("_")[0] as TimeInterval
    const region = (selectedRegion || ALL).toLowerCase()
    hashParams.append("region", region)
    hashParams.append("time", timeRange)
    hashParams.append("organisation", organisationId)
    setContextOrganisationId(organisationId)
    navigate(`/private#${hashParams.toString()}`)
  }

  const originalZoom = "original" as const
  const options: ChartOptions<"treemap"> = {
    onClick: handleClick,
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        limits: {
          y: { min: 0, max: originalZoom },
          x: { min: 0, max: originalZoom },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
        },
        pan: {
          enabled: true,
        }
      },
      tooltip: {
        enabled: false,
      },
    },
  }

  useEffect(() => {
    window.history.replaceState({}, document.title, window.origin + "/customergraph")
    void (async function() {
      const trendsApi = new TrendsApi(process.env.REACT_APP_IX_STATS_BACKEND_URL)
      let newScopeData: DeltaStats[] = []
      switch (selectedScope) {
      case "weekly":
        newScopeData = await trendsApi.getWeeklyTrend(selectedRegion)
        break
      case "monthly":
        newScopeData = await trendsApi.getMonthlyTrend(selectedRegion)
        break
      case "weekly_peak":
        newScopeData = await trendsApi.getWeeklyPeakTrend(selectedRegion)
        break
      case "monthly_peak":
        newScopeData = await trendsApi.getMonthlyPeakTrend(selectedRegion)
        break
      }
      const overflowFit = "fit"
      setConfig({
        type: "treemap",
        data: {
          datasets: [
            {
              tree: parseScopeData(newScopeData),
              data: [],
              key: "bandwidth",
              labels: {
                display: true,
                font: [{ weight: "bold" }, { weight: "normal" }],
                formatter: (context: TreemapScriptableContext) => {
                  const raw = context.raw as TreemapDataPoint & {_data: BandwidthData}
                  return [raw._data.name, FormatTrafficRate(raw._data.bandwidth)]
                },
                overflow: overflowFit,
              },
              backgroundColor(context: TreemapScriptableContext) {
                if (context.type !== "data") return "transparent"
                const raw = context.raw as TreemapDataPoint & {_data: BandwidthData}
                const { gradient, base_color } = raw._data
                return color(base_color).alpha(gradient ?? 0).rgbString()
              },
            }
          ]
        },
      })
    })()
  }, [setConfig, selectedRegion, selectedScope])

  let description = ""
  switch (selectedScope) {
  case "weekly":
    description = "The weekly average scope shows the average rate for the sum of traffic in both directions."
    break
  case "monthly":
    description = "The monthly average scope shows the average rate for the sum of traffic in both directions."
    break
  case "weekly_peak":
    description = "The weekly peak scope shows the highest peak in either direction of traffic."
    break
  case "monthly_peak":
    description = "The monthly peak scope shows the highest peak in either direction of traffic."
    break
  }
  const topHeight = (window.document.getElementById("dropdown")?.clientHeight || 0) + (window.document.getElementById("description")?.clientHeight || 0)

  return (
    <Box style={{ height: `calc(100vh - 20px - ${topHeight}px)` }}>
      <Grid id="dropdown" container spacing={2} sx={{ marginBottom: "10px" }}>
        <Grid item xs={12} sm={8}>
          <Dropdown
            options={scopeOptions}
            value={selectedScope}
            onChange={({ target: { value } }) => setSelectedScope(value as Scope)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RegionSelector
            initialSelectedRegion={selectedRegion}
            onChange={(newRegion) => setSelectedRegion(newRegion)} />
        </Grid>
      </Grid>
      <Box id="description" sx={{ marginTop: "10px", marginBottom: "10px" }}>{description}</Box>
      <Chart type="treemap" data={config.data as ChartData<"treemap">} options={options} />
    </Box>
  )
}
